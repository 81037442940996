<template>
  <div class="login">
    <van-nav-bar
      title="甬农鲜商家登录"
      left-text="返回"
      left-arrow
      fixed
      @click-left="$router.go(-1)"
    />
    <div class="user-photo">
      <van-image width="100" height="100" round fit="cover" />
    </div>
    <div class="login-form">
      <van-form>
        <van-field
          v-model="username"
          name="账号"
          left-icon="manager"
          placeholder="手机号码"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        />
        <van-field
          v-model="password"
          :type="isShowPass ? 'text' : 'password'"
          name="密码"
          left-icon="lock"
          placeholder="登录密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        >
          <template slot="right-icon">
            <van-icon
              :name="isShowPass ? 'closed-eye' : 'eye'"
              @click="isShowPass = !isShowPass"
            />
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button round block type="info" size="small" @click="onSubmit"
            >登录</van-button
          >
        </div>
      </van-form>
    </div>
    <!-- <div class="find-password">
      <div @click="$router.push('/findpass')">找回密码</div>
    </div> -->
  </div>
</template>

<script>
import { login, getRole } from "@/api/home"
import Cookies from "js-cookie";
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      username: '',
      password: '',
      isShowPass: false
    };
  },
  mounted () { },
  watch: {},
  methods: {
    async getHxhc () {
      const res = await getRole()
      Cookies.set("isHxhc", res.data)
      this.$router.push('/home')
    },
    async onSubmit () {
      try {
        const res = await login({
          __login: true,
          __ajax: "json",
          username: this.username,
          password: this.password
        })
        if (res.message == "登录成功！") {
          this.$toast.success('登录成功')
          Cookies.set("token", res.sessionid);
          this.$router.push('/home')
        } else if (res.result == "false") {
          this.$toast.fail(res.message)
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  padding-top: 46px;
  .user-photo {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-form {
    margin: 0 20px;
  }
  .find-password {
    font-size: 14px;
    display: flex;
    justify-content: center;
    > div {
      width: 120px;
      text-align: center;
    }
  }
}
</style>>